<template>
    <div class="goods-detail">
        <div class="preview-wrap">
            <div class="video-player-wrap" :class="{ show: switchMedia == 'video' }" v-if="goodsSkuDetail.video_url != ''">
                <video-player
                    v-if="goodsSkuDetail.video_url != ''"
                    ref="videoPlayer"
                    :playsinline="true"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @pause="onPlayerPause($event)"
                    @ended="onPlayerEnded($event)"
                    @waiting="onPlayerWaiting($event)"
                    @playing="onPlayerPlaying($event)"
                    @loadeddata="onPlayerLoadeddata($event)"
                    @timeupdate="onPlayerTimeupdate($event)"
                    @canplay="onPlayerCanplay($event)"
                    @canplaythrough="onPlayerCanplaythrough($event)"
                    @statechanged="playerStateChanged($event)"
                    @ready="playerReadied"
                ></video-player>

                <div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
                    <span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">视频</span>
                    <span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">图片</span>
                </div>
            </div>
            <!-- , { size: 'big' } -->
            <div class="magnifier-wrap">
                <pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
            </div>

            <div class="spec-items">
                <span class="left-btn iconfont iconarrow-left-copy" :class="{ move: moveThumbLeft }" @click="changeThumbImg('prev')"></span>
                <span class="right-btn iconfont iconarrow-right" :class="{ move: moveThumbRight }" @click="changeThumbImg('next')"></span>
                <ul :style="{ left: 30 + thumbPosition + 'px' }">
                    <!-- 商品缩率图 -->
                    <li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @mousemove="picZoomUrl = item" :class="{ selected: picZoomUrl == item }">
                        <img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
                    </li>
                </ul>
            </div>

            <!--			<div class="share-collect" @click="editCollection">
				<i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>
				<span data-collects="0">关注商品（{{ goodsSkuDetail.collect_num }}）</span>
			</div>-->

            <div class="share-collect">
                <span @click="editCollection">
                    <i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>
                    <span data-collects="0" class="focus-text">关注商品（{{ goodsSkuDetail.collect_num }}）</span>
                </span>

                <!-- <span @click="service_link" style="float: right">
                    <i class="el-icon-chat-dot-square" style="font-size: 18px"></i>
                    <span data-collects="0">联系商家</span>
                </span> -->
            </div>
        </div>

        <!-- 商品信息 -->
        <div class="basic-info-wrap" v-loading="loading">
            <h1>
                <span class="p_tag_832_detail" v-if="goodsSkuDetail.tag_type == 1">832产品</span>
                <span class="p_tag_832_detail" v-if="goodsSkuDetail.tag_level == 2">省贫认定</span>
                <span class="p_tag_832_detail" v-if="goodsSkuDetail.tag_level == 3">市贫认定</span>
                <span class="p_tag_832_detail" v-if="goodsSkuDetail.tag_level == 4">县贫认定</span>
                {{ goodsSkuDetail.sku_name }}
            </h1>
            <p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p>

            <div class="discount-banner ns-bg-color" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime && addonIsExit.discount">
                <div class="activity-name">
                    <i class="discount-icon iconfont iconicon_naozhong"></i>
                    <span>限时折扣</span>
                </div>
                <div class="surplus-time">
                    <span>{{ discountText }}</span>
                    <count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()" :currentTime="discountTimeMachine.currentTime" :startTime="discountTimeMachine.startTime" :endTime="discountTimeMachine.endTime" :dayTxt="'天'" :hourTxt="'小时'" :minutesTxt="'分钟'" :secondsTxt="'秒'"></count-down>

                    <!-- :tipText="'距离开始文字1'"
					:tipTextEnd="'距离结束文字1'"
                    :endText="'结束自定义文字2'"-->
                </div>
            </div>

            <div class="item-block">
                <div class="promotion-price">
                    <dl class="item-line">
                        <dt class="ns-text-color-gray">销售价</dt>
                        <dd>
                            <em class="yuan ns-text-color">¥</em>
                            <span class="price ns-text-color">{{ goodsSkuDetail.discount_price }}</span>
                        </dd>
                    </dl>
                    <dl class="item-line" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime">
                        <dt class="ns-text-color-gray">原价</dt>
                        <dd>
                            <em class="market-yuan">¥</em>
                            <span class="market-price">{{ goodsSkuDetail.price }}</span>
                        </dd>
                    </dl>
                    <dl class="item-line" v-if="goodsSkuDetail.market_price > 0">
                        <dt class="ns-text-color-gray">市场价</dt>
                        <dd>
                            <em class="market-yuan">¥</em>
                            <span class="market-price">{{ goodsSkuDetail.market_price }}</span>
                        </dd>
                    </dl>

                    <div class="statistical">
                        <ul>
                            <li>
                                <p>累计评价</p>
                                <span>{{ goodsSkuDetail.evaluate }}</span>
                            </li>
                            <li>
                                <p>累计销量</p>
                                <span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
                            </li>
                        </ul>
                    </div>

                    <dl class="item-line coupon-list" v-if="addonIsExit.coupon && couponList.length">
                        <dt class="ns-text-color-gray">优惠券</dt>
                        <div>
                            <dd>
                                <p v-for="(item, index) in couponList" :key="index" class="ns-text-color" @click="receiveCoupon(item.coupon_type_id)">
                                    <span class="ns-border-color" v-if="item.type == 'discount'">{{ item.discount }}折</span>
                                    <span class="ns-border-color" v-if="item.type == 'reward'">￥{{ item.money }}</span>
                                    <label v-if="item.type == 'discount'">{{ item.discount }}折</label>
                                    <label v-if="item.type == 'reward'">￥{{ item.money }}</label>
                                </p>
                            </dd>
                        </div>
                    </dl>
                    <dl class="item-line manjian" v-if="addonIsExit.manjian && manjian.manjian_name">
                        <dt>满减</dt>
                        <dd>
                            <i class="i-activity-flag ns-text-color ns-border-color">{{ manjian.manjian_name }}</i>
                            <span v-for="(item, index) in manjian.rule_json" :key="index">满{{ item.money }}减{{ item.discount_money }}元；</span>
                        </dd>
                    </dl>
                    <dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
                        <dt>运费</dt>
                        <dd>
                            <i class="i-activity-flag ns-text-color ns-border-color" v-if="goodsSkuDetail.is_free_shipping === 1">快递免邮</i>
                            <i class="i-activity-flag ns-text-color ns-border-color" v-else>快递不免邮</i>
                        </dd>
                    </dl>
                </div>
            </div>
            <dl class="item-line delivery" v-if="goodsSkuDetail.is_virtual == 0">
                <dt>配送至</dt>
                <dd>
                    <div class="region-selected ns-border-color-gray">
                        <span>
                            <template v-if="selectedAddress['level_1']">
                                <template v-for="item in selectedAddress">
                                    {{ item.name }}
                                </template>
                            </template>
                            <template v-else> 请选择配送地址 </template>
                        </span>
                        <i class="el-icon-arrow-down"></i>
                    </div>

                    <div class="region-list ns-border-color-gray" :class="{ hide: hideRegion }">
                        <ul class="nav-tabs">
                            <li :class="{ active: currTabAddres == 'province' }" @click="currTabAddres = 'province'">
                                <div>
                                    <span>{{ selectedAddress["level_1"] ? selectedAddress["level_1"].name : "请选择省" }}</span>
                                    <i class="el-icon-arrow-down"></i>
                                </div>
                            </li>
                            <li :class="{ active: currTabAddres == 'city' }" @click="currTabAddres = 'city'">
                                <div>
                                    <span>{{ selectedAddress["level_2"] ? selectedAddress["level_2"].name : "请选择市" }}</span>
                                    <i class="el-icon-arrow-down"></i>
                                </div>
                            </li>
                            <li :class="{ active: currTabAddres == 'district' }" @click="currTabAddres = 'district'">
                                <div>
                                    <span>{{ selectedAddress["level_3"] ? selectedAddress["level_3"].name : "请选择区/县" }}</span>
                                    <i class="el-icon-arrow-down"></i>
                                </div>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane" :class="{ active: currTabAddres == 'province' }">
                                <ul class="province">
                                    <li v-for="(item, index) in provinceArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                                        <span @click="getAddress('city', item)">{{ item.name }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-pane" :class="{ active: currTabAddres == 'city' }">
                                <ul class="city">
                                    <li v-for="(item, index) in cityArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                                        <span @click="getAddress('district', item)">{{ item.name }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-pane" :class="{ active: currTabAddres == 'district' }">
                                <ul class="district">
                                    <li v-for="(item, index) in districtArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                                        <span @click="getAddress('community', item)">{{ item.name }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </dd>
            </dl>
            <dl class="item-line service">
                <dt>服务</dt>
                <dd>
                    <span>
                        由
                        河南消费帮扶
                        <!-- <router-link :to="'/shop-' + shopInfo.site_id" class="ns-text-color">{{ shopInfo.site_name }}</router-link> -->
                        发货并提供售后服务
                    </span>
                </dd>
            </dl>
            <!-- <dl class="item-line service">
				<dt>比价</dt>
				<dd>
					<span>
                           <div v-if="shopInfo.bijia.is_show == 1" class="item">比其他平台均价{{ shopInfo.bijia.moreOrLess =='+'? '贵':'省'}}了 <span :class="[shopInfo.bijia.moreOrLess =='+'? 'many':'less']">{{ shopInfo.bijia.moreOrLessPrice }}</span> 元哟</div>
              <div class="compare" v-if="shopInfo.bijia.is_show == 1">
                <span><img src="@/assets/images/jingdong.png" alt="">京东 ￥{{shopInfo.bijia.jd_price}}</span>
                <span><img src="@/assets/images/taobao.png" alt="">淘宝 ￥{{shopInfo.bijia.tb_price}}</span>
              </div>
					</span>
				</dd>
			</dl> -->
            <hr class="divider" />
            <div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
                <dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
                    <dt style="overflow: hidden; height: auto; line-height: 18px" :title="item.spec_name">{{ item.spec_name }}</dt>
                    <dd>
                        <ul>
                            <li v-for="(item_value, index_value) in item.value" :key="index_value">
                                <div
                                    :class="{
                                        'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
                                        disabled: item_value['disabled'] || (!item_value['selected'] && specDisabled)
                                    }"
                                    @click="changeSpec(item_value.sku_id, item_value.spec_id)"
                                >
                                    <img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
                                    <span>{{ item_value.spec_value_name }}</span>
                                    <i class="iconfont iconduigou1 ns-text-color"></i>
                                </div>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </div>

            <div class="buy-number">
                <dl class="item-line">
                    <dt>数量</dt>
                    <dd>
                        <div class="num-wrap">
                            <el-input v-model="number" placeholder="0" @input="keyInput()"></el-input>
                            <div class="operation">
                                <span class="increase el-icon-caret-top" @click="changeNum('+')"></span>
                                <span class="decrease el-icon-caret-bottom" @click="changeNum('-')"></span>
                            </div>
                        </div>
                        <span class="unit">{{ goodsSkuDetail.unit }}</span>
                        <span class="inventory">库存{{ goodsSkuDetail.stock }}{{ goodsSkuDetail.unit }}</span>
                        <!-- 批发起购数量 -->
                        <span class="ns-text-color" v-if="goodsSkuDetail.min_buy > 1">（{{ goodsSkuDetail.min_buy }}件起售）</span>
                        <!-- 限购 -->
                        <!-- <em>(每人限购1件)</em> -->
                        <span class="ns-text-color" v-if="goodsSkuDetail.max_buy > 0">（每人限购{{ goodsSkuDetail.max_buy }}件）</span>
                    </dd>
                </dl>
            </div>

            <div class="buy-number" v-if="goodsSkuDetail.special && goodsSkuDetail.special.length > 0">
                <dl class="item-line" style="line-height: 20px">
                    <dt>标签</dt>
                    <dd>
                        <span v-for="(item, index) in goodsSkuDetail.special" :key="index">
                            <img :src="bindIcon(item.special_type)" style="height: 16px; width: auto; margin-right: 8px" />
                        </span>
                    </dd>
                </dl>
            </div>

            <dl class="item-line buy-btn">
                <dt></dt>
                <dd>
                    <template v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 && goodsSkuDetail.buy_type == 'money'">
                        <el-button type="primary" plain @click="buyNow">立即购买</el-button>
                        <el-button type="primary" icon="el-icon-shopping-cart-2" v-if="goodsSkuDetail.is_cart == 1" @click="joinCart">加入购物车</el-button>
                    </template>
                    <template v-else-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 && goodsSkuDetail.buy_type == 'exchange'">
                        <el-button type="primary" plain @click="buyNow">兑换</el-button>
                    </template>
                    <template v-else>
                        <el-button type="info" plain disabled>该商品已下架</el-button>
                    </template>
                    <div href="javascript:;" class="go-phone" style="display: block">
                        <img src="@/assets/images/goods/qrcode.png" />
                        <div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>
                    </div>
                </dd>
            </dl>

            <dl class="item-line merchant-service" v-show="shopInfo.shop_baozh == 1 || shopInfo.shop_qtian == 1 || shopInfo.shop_zhping == 1 || shopInfo.shop_erxiaoshi == 1 || shopInfo.shop_tuihuo == 1 || shopInfo.shop_shiyong == 1 || shopInfo.shop_shiti == 1 || shopInfo.shop_xiaoxie == 1">
                <dt>商家服务</dt>
                <div>
                    <dd v-if="shopInfo.shop_baozh == 1">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" title="保证服务">保证服务</span>
                    </dd>
                    <dd v-if="shopInfo.shop_qtian == 1">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" title="满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费">7天退换</span>
                    </dd>
                    <dd v-if="shopInfo.shop_zhping == 1">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" title="商品支持正品保障服务">正品保障</span>
                    </dd>
                    <dd v-if="shopInfo.shop_erxiaoshi == 1">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" title="付款后2小时内发货">两小时发货</span>
                    </dd>
                    <dd v-if="shopInfo.shop_tuihuo == 1">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" title="退货承诺">退货承诺</span>
                    </dd>
                    <dd v-if="shopInfo.shop_shiyong == 1">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" title="试用中心">试用中心</span>
                    </dd>
                    <dd v-if="shopInfo.shop_shiti == 1">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" title="实体验证">实体验证</span>
                    </dd>
                    <dd v-if="shopInfo.shop_xiaoxie == 1">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" title="如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担">消协保证</span>
                    </dd>
                </div>
            </dl>
        </div>

        <!-- 店铺信息 -->
        <div class="shop-wrap" v-if="flase" style="float: left; margin-left: 10px">
            <div class="head-wrap">
                <div class="img-wrap" style="margin-top: 8px">
                    <img class="img-responsive center-block" :src="shopInfo.avatar ? $img(shopInfo.avatar) : $img(defaultShopImage)" @error="shopInfo.avatar = defaultShopImage" />
                </div>
                <h5>
                    <span class="site-name">{{ shopInfo.site_name }}</span>
                    <el-tag class="tag" size="small" v-if="shopInfo.is_own == 1">自营</el-tag>
                </h5>
            </div>
            <div class="info-wrap">
                <dl>
                    <dt class="site-score">店铺评分</dt>
                    <dd>
                        <el-rate v-model="score" disabled></el-rate>
                    </dd>
                    <dt>商品描述：</dt>
                    <dd>
                        <span>{{ shopInfo.shop_desccredit }}</span>
                        分
                    </dd>
                    <dt>卖家服务：</dt>
                    <dd>
                        <span>{{ shopInfo.shop_servicecredit }}</span>
                        分
                    </dd>
                    <dt>发货速度：</dt>
                    <dd>
                        <span>{{ shopInfo.shop_deliverycredit }}</span>
                        分
                    </dd>
                </dl>
            </div>
            <div class="info-wrap">
<!-- 2022-10-10注释 -->
               <dl>
                    <dt>联系电话：</dt>
                    <dd>400-080-8860</dd>
                    <!-- <dd v-if="!logined">*******</dd>
                    <dd v-if="logined">{{ shopInfo.telephone ? shopInfo.telephone : "暂无" }}</dd> -->
                </dl>
                <!-- <dl>
                    <dt>联系商家：</dt>
                    <dd @click="service_link" style="cursor: pointer; color: deepskyblue; font-size: 20px"><i class="el-icon-chat-dot-square"></i></dd>
                </dl> -->
            </div>
            <div class="operation" v-if="shopInfo.site_id">
                <el-button class="btn btn-default" size="medium" @click="$router.pushToTab('shop-' + shopInfo.site_id)">进店逛逛</el-button>
                <el-button size="medium" @click="follow" v-if="hasFollow">取消关注</el-button>
                <el-button size="medium" @click="follow" v-else>关注店铺</el-button>
            </div>
        </div>

        <!-- 全网比价 -->
        <!-- <div class="price-comparison" v-if="shopInfo.bijia.is_show == 1">
			<h4>全网比价</h4>
			<div class="price-comparison-content">
				<div class="price-comparison-item">
					<div class="price-comparison-item__header">
						<span class="platform-name"><img src="@/assets/images/jingdong.png" alt="">京东</span>
						<span class="platform-price">平均价:<strong> ¥{{shopInfo.bijia.jd_price}}</strong></span>
					</div>
				</div>
				<div class="price-comparison-item">
					<div class="price-comparison-item__header">
						<span class="platform-name"><img src="@/assets/images/taobao.png" alt="">淘宝</span>
						<span class="platform-price">平均价:<strong> ¥{{shopInfo.bijia.tb_price}}</strong></span>
					</div>
				</div>
				<div class="price-comparison-item">
					<div class="price-comparison-item__header">
						<span class="platform-name"><img src="@/assets/images/thirdparty.png" alt="">第三方</span>
						<span class="platform-price">平均价:<strong> ¥{{ goodsSkuDetail.discount_price }}</strong></span>
					</div>
				</div>
				<div class="price-comparison-item">
					<div class="price-comparison-item__header">
						<span class="platform-name"><img :src="$img(goodsSkuDetail.sku_image, { size: 'small' })" />本平台</span>
						<span class="platform-price">平均价:<strong> ¥{{ goodsSkuDetail.discount_price }}</strong></span>
					</div>
					<div class="price-comparison-item__body">
						<el-carousel indicator-position="outside" height="150px">
							<el-carousel-item v-for="item in 4" :key="item">
								<div class="price-comparison-goods">
									<div class="img-wrap"><img :src="$img(goodsSkuDetail.sku_image, { size: 'small' })" /></div>
									<div class="price">￥{{ goodsSkuDetail.discount_price }}</div>
									<p class="sku-name">{{ goodsSkuDetail.sku_name }}</p>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
		</div> -->

        <!-- 组合套餐 -->
        <el-tabs class="bundling-wrap" v-model="tabBundling" @tab-click="bundlingChange" v-if="addonIsExit.bundling && bundling.length && bundling[0].bl_name">
            <el-tab-pane :label="item.bl_name" :name="'bundling_' + item.bl_id" v-for="(item, index) in bundling" :key="index">
                <div class="master">
                    <div class="sku-img"><img :src="$img(goodsSkuDetail.sku_image, { size: 'small' })" /></div>
                    <div class="sku-name">{{ goodsSkuDetail.sku_name }}</div>
                    <div class="sku-price ns-text-color">￥{{ goodsSkuDetail.price }}</div>
                    <i class="el-icon-plus"></i>
                </div>
                <div class="operation">
                    <div class="price-wrap">
                        <span>组合套餐价</span>
                        <strong class="bl-price ns-text-color">￥{{ item.bl_price }}</strong>
                    </div>
                    <el-button type="primary" size="medium" @click="$router.pushToTab('promotion/combo-' + item.bl_id)">立即购买</el-button>
                    <i class="equal">=</i>
                </div>

                <div class="suits">
                    <ul>
                        <li v-for="(goods, goods_index) in item.bundling_goods" :key="goods_index" @click="$router.pushToTab({ path: '/sku-' + goods.sku_id })">
                            <div class="sku-img"><img :src="$img(goods.sku_image, { size: 'small' })" /></div>
                            <div class="sku-name">{{ goods.sku_name }}</div>
                            <div class="sku-price ns-text-color">￥{{ goods.price }}</div>
                        </li>
                    </ul>
                </div>
            </el-tab-pane>
        </el-tabs>
		
		<div class="detail-wrap" style="margin: 20px 0;" v-if="goodsSkuDetail.is_combination == 1">
			<div>套餐商品</div>
			<div class="itembox">
				<div class="item" v-for="(item,indexx) in combination" :key="index" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
					<img class="itemimg" :src="item.sku_image" />
					<div class="itemname">{{item.sku_name}}</div>
					<div class="itemmoney">￥{{item.price}}</div>
				</div>
			</div>
		</div>

        <div class="detail-wrap">
            <div style="float: left; width: 254px">
                <div class="goods-recommended">
                    <goods-recommend />
                </div>
            </div>

            <el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
                <el-tab-pane label="商品详情" name="detail">
                    <ul class="attr-list">
                        <template v-if="goodsSkuDetail.goods_attr_format && goodsSkuDetail.goods_attr_format.length > 0">
                            <li v-for="(item, index) in goodsSkuDetail.goods_attr_format" :key="index">{{ item.attr_name }}：{{ item.attr_value_name }}</li>
                        </template>
                    </ul>
                    <div v-html="goodsSkuDetail.goods_content" class="goods-detail"></div>
                </el-tab-pane>
                <template v-if="helpClassID == 0">
                    <el-tab-pane :label="goodsSkuDetail.evaluate ? '商品评价(' + goodsSkuDetail.evaluate + ')' : '商品评价'" name="evaluate" class="evaluate">
                        <template v-if="goodsEvaluateList.length">
                            <nav>
                                <li class="selected">全部评价</li>
                                <!-- <li>好评</li>
						<li>中评</li>
                            <li>差评</li>-->
                            </nav>
                            <ul class="list">
                                <li v-for="(item, index) in goodsEvaluateList" :key="index">
                                    <div class="member-info">
                                        <img :src="$img(item.member_headimg)" @error="imageErrorEvaluate(index)" class="avatar" />
                                        <span>{{ item.member_name }}</span>
                                    </div>
                                    <div class="info-wrap">
                                        <el-rate v-model="item.star" disabled></el-rate>
                                        <p class="content">{{ item.content }}</p>
                                        <div class="img-list" v-if="item.images">
                                            <el-image v-for="(img, img_index) in item.images" :key="img_index" :src="$img(img)" :preview-src-list="item.imagesFormat"></el-image>
                                        </div>
                                        <div class="sku-info">
                                            <span>{{ item.sku_name }}</span>
                                            <span class="create-time">{{ $util.timeStampTurnTime(item.create_time) }}</span>
                                        </div>
                                        <div class="evaluation-reply" v-if="item.explain_first != ''">店家回复：{{ item.explain_first }}</div>
                                        <template v-if="item.again_content != ''">
                                            <div class="review-evaluation">
                                                <span>追加评价</span>
                                                <span class="review-time">{{ $util.timeStampTurnTime(item.again_time) }}</span>
                                            </div>
                                            <p class="content">{{ item.again_content }}</p>
                                            <div class="img-list">
                                                <el-image v-for="(again_img, again_index) in item.again_images" :key="again_index" :src="$img(again_img)" :preview-src-list="item.againImagesFormat"></el-image>
                                            </div>
                                            <div class="evaluation-reply" v-if="item.again_explain != ''">店家回复：{{ item.again_explain }}</div>
                                        </template>
                                    </div>
                                </li>
                            </ul>
                            <div class="pager">
                                <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
                            </div>
                        </template>
                        <div class="empty" v-else>该商品暂无评价哦</div>
                    </el-tab-pane>
                    <el-tab-pane label="商品溯源" v-if="false" class="evaluate">
                        <template v-if="shopInfo.camera_list && shopInfo.camera_list.length > 0">
                            <div id="timeline">
                                <div class="timeline-item" v-for="(item, index) of shopInfo.camera_list" :key="index">
                                    <div class="timeline-icon">
                                        <img src="assets/images/star.svg" alt="" />
                                    </div>
                                    <div class="timeline-content">
                                        <h2>{{ item.group_name }}</h2>
                                        <div class="panel-body">
                                            <div class="cameraImg" @click="cameraShow(citem)" v-for="(citem, cindex) of item.item" :key="cindex">
                                                <img :src="citem.camera_img" alt="" />
                                                <div class="cameraImgBg" title="点击播放">
                                                    <img src="@/assets/images/play.png" alt="" />
                                                </div>
                                                <div class="cameraName">{{ citem.camera_name }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="empty" v-else>该商品暂无直播溯源</div>
                    </el-tab-pane>
                </template>
                <template v-if="helpClassID != 0">
                    <el-tab-pane v-for="(help, help_index) in helpList" :key="help_index" :label="help.title" :name="'tab_' + help.id" class="after-sale">
                        <div v-html="helpContent"></div>
                    </el-tab-pane>
                </template>
            </el-tabs>
        </div>
        <el-dialog :title="camera_name" :visible.sync="liveShow" width="55%" :before-close="cameramodalcancel">
            <video autoplay muted controls width="100%" height="500" id="videoElement"></video>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="cameramodalcancel">确 定</el-button>
            </span>
        </el-dialog>

        <!--联系客服弹窗-->
        <servicerMessage ref="servicerMessage" class="kefu" :shop="{ shop_id: shopInfo.site_id, logo: shopInfo.logo, shop_name: shopInfo.site_name }"></servicerMessage>
    </div>
</template>

<script>
import PicZoom from "vue-piczoom"
import detail from "./detail"
import GoodsRecommend from "@/components/GoodsRecommend"
import servicerMessage from "@/components/message/servicerMessage"
import Config from "@/utils/config"
import Cookies from "js-cookie"

export default {
    name: "detail",
    components: {
        PicZoom,
        GoodsRecommend,
        servicerMessage,
        Config
    },
    mixins: [detail],
    data() {
        return {
            // 要进行播放的rtmp地址
            nowPlayVideoUrl: "rtmp://yunbo01.hn-gdjt.com/xczw/sybbg05?auth_key=1650478852-0-0-a10184da0a41f1d303942d9f6803032a",
            website_id: "",
            website_type: "",
            baseurl: Config.baseUrl
        }
    },
    created() {
        this.setWebsiteInfo()
    },
    mounted() {},
    methods: {
        // 获取分站id和分站类型
        setWebsiteInfo() {
            this.website_id = Cookies.get("website_id") ? Cookies.get("website_id") : ""
            this.website_type = Cookies.get("website_type") ? Cookies.get("website_type") : ""
        },
        bindIcon(icon) {
            return this.baseurl + "public/static/img/wxapp/detail/special_" + icon + ".png"
        }
    }
}
</script>
<style lang="scss">
@import "./detail.scss";
.many {
    color: #d80000;
}
.less {
    color: #1ce08d;
}
.compare {
    display: flex;
    color: #666;
    span {
        display: flex;
        align-items: center;
        width: 140px;
        img {
            margin-right: 5px;
        }
    }
}

/*产品溯源*/
#timeline .timeline-item:after,
#timeline .timeline-item:before {
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
#timeline {
    width: 98%;
    margin: 30px auto;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
#timeline:before {
    content: "";
    width: 0px;
    border: 1px dashed $base-color;
    height: 100%;
    left: 50%;
    top: 0;
    position: absolute;
}
#timeline:after {
    content: "";
    clear: both;
    display: table;
    width: 100%;
}
#timeline .timeline-item {
    margin-bottom: 20px;
    position: relative;
}
#timeline .timeline-item .timeline-icon {
    background: $base-color-bg;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 8px;
    left: 50%;
    overflow: hidden;
    margin-left: -7px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}
#timeline .timeline-item .timeline-icon img {
    position: relative;
    top: 14px;
    left: 14px;
}
#timeline .timeline-item .timeline-content {
    width: 47%;
    background: #fff;
    padding: 0px;
    -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#timeline .timeline-item .timeline-content h2 {
    padding: 0px 15px;
    height: 32px;
    line-height: 32px;
    background: $base-color-bg;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}
#timeline .timeline-item .timeline-content:before {
    content: "";
    position: absolute;
    left: 47%;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid $base-color;
}
#timeline .timeline-item:nth-child(odd) .timeline-content {
    float: right;
}
#timeline .timeline-item:nth-child(odd) .timeline-content:before {
    content: "";
    right: 47%;
    left: inherit;
    border-left: 0;
    border-right: 7px solid $base-color;
}
#timeline .timeline-item .timeline-content .panel-body {
    padding: 12px 0px 0px 10px;
    position: relative;
    z-index: 10;
    display: block;
    margin: 0px;
    width: 100%;
}
#timeline .timeline-item .timeline-content .panel-body .cameraImg {
    width: 96px;
    height: 85px;
    display: inline-block;
    overflow: hidden;
    margin-right: 10px;
    position: relative;
}
#timeline .timeline-item .timeline-content .panel-body .cameraName {
    font-size: 12px;
    color: #333;
    background-color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 3;
}
#timeline .timeline-item .timeline-content .panel-body img {
    position: absolute;
    z-index: 1;
}
#timeline .timeline-item .timeline-content .panel-body .cameraImgBg {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 20px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
    img {
        width: 22px !important;
        min-width: 22px !important;
        z-index: 1;
        margin-top: -20px;
    }
}

/* 比价*/
.price-comparison {
    float: right;
    border: 1px solid #e9e9e9;
    padding: 0;
    margin: 0;
    width: 234px;
    box-sizing: border-box;
    h4 {
        background-color: #f9f9f9;
        color: #000;
        font-size: 14px;
        line-height: 38px;
        height: 38px;
        padding: 0px 10px;
    }
    .price-comparison-content {
        margin: 0px 10px;

        .price-comparison-item {
            position: relative;
            margin: 0 auto;
            border-top: 1px solid #ebeef5;
            .price-comparison-item__header {
                display: flex;
                align-items: center;
                height: 42px;
                line-height: 42px;
                background-color: #fff;
                font-size: 13px;
                font-weight: 500;
                outline: none;

                .platform-name {
                    float: left;
                    display: inline-block;
                    width: 120px;
                    font-weight: 500;
                    color: #303133;
                    img {
                        width: 16px;
                        height: 16px;
                        min-width: 16px;
                        max-width: 16px;
                        margin-right: 5px;
                        display: inline-block;
                    }
                }

                .platform-price {
                    display: inline-block;
                    width: 120px;
                    font-size: 11px;
                    color: #666;

                    strong {
                        color: #d81e01;
                    }
                }
            }
            .price-comparison-item__body {
                margin: 8px auto;
                text-align: center;
                padding: 0px 20px;
                .price-comparison-goods {
                    cursor: pointer;
                    .img-wrap {
                        width: 110px;
                        height: auto;
                        margin: 0 auto;
                        text-align: center;
                        img {
                            width: 100%;
                            border-style: none;
                            max-width: 100%;
                            vertical-align: middle;
                        }
                    }
                    .price {
                        color: $base-color;
                        font-size: 14px;
                        line-height: 24px;
                        height: 24px;
                    }
                    .sku-name {
                        font-size: 12px;
                        height: 20px;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    &:hover {
                        color: $base-color;
                    }
                }
            }
        }
        .price-comparison-item:first-child {
            border-top: 0;
        }
    }
}
#pane-detail {
    p {
        text-align: left;
    }
    img {
        margin: 0 auto;
        min-width: 100%;
    }
}
</style>
